import { template as template_b81db4075f8a4d96b65d200cd81c9442 } from "@ember/template-compiler";
const FKControlMenuContainer = template_b81db4075f8a4d96b65d200cd81c9442(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
