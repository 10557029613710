import { template as template_bd1c32993a804d55bfa6c48bce4e392d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_bd1c32993a804d55bfa6c48bce4e392d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
