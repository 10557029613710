import { template as template_b9f2ebc8215c4ad58dbcad1990024b06 } from "@ember/template-compiler";
const WelcomeHeader = template_b9f2ebc8215c4ad58dbcad1990024b06(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
